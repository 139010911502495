#print-content {
  padding: 20px;
}

.form-footer {
  position: absolute;
  bottom: 0px;
  padding: 5px;
}

#kendo-content {
  position: absolute;
  left: -3000px;
  max-width: 3000px;
}

.form-print-pdf .mdgray {
  color: #797f8d !important;
}

.form-print {
  margin: auto;
  margin-bottom: 40px;
  width: 920px !important;
}

.form-footer p {
  font-size: 5pt;
}

.form-title h1 {
  font-size: 17pt;
}

.form-title h2 {
  font-size: 14pt;
}

.form-print section {
  margin-bottom: 12px;
}

.form-print-pdf {
  margin: auto;
  margin-bottom: 40px;
  height: 800px;
  width: 100% !important;
}

.arf-print-pdf {
  padding-bottom: 150px !important;
}

/* .main-pdf-content{
        min-height:500px !important;
    } */

// .form-print-pdf section {
//   margin-bottom: 9px;
// }

.form-print-pdf .m-portlet__body {
  padding: 40px;
}

.form-print-pdf {
  .main-label {
    color: #555;
    font-size: 5pt;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
  }
}

.form-print-pdf p {
  color: #000;
  font-size: 9px;
  font-weight: 400;
  margin: 0;
}

.semibold {
  font-weight: 500;
}

.form-print-pdf h4 {
  font-size: 11pt;
}

.form-print-pdf h5 {
  font-size: 11pt;
}

.form-header {
  height: 90px;
  padding-bottom: 10px;
}

.form-header p {
  font-size: 7pt;
  font-weight: 600;
}

#headerLogo {
  // width: 110px;
  height: 38px;
}

#headerLogo_1call {
  // width: 110px;
  height: 45px;
}

.form-title-left {
  max-width: 500px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.form-bid-package {
  border-top: 2px solid #000 !important;
}

.border-dark {
  border-color: black !important;
}

.form-highlights {
  border-bottom: 2px solid #000;
  border-top: 2px solid #000;
  padding-bottom: 6px;
}

.border-bottom-heavy {
  border-bottom: 2px solid #000;
}

.form-highlights h6 {
  color: #000;
  font-size: 6pt;
}

.form-highlights .form-border-divider {
  padding-top: 6px;
  margin: 0 !important;
}

.border-bottom-gray {
  border-bottom: 1px solid #d3d3d3;
}

.form-section-divider {
  border-bottom: 1px solid #d3d3d3;
  margin: 0 !important;
  margin-bottom: 20px;
  padding-top: 20px;
}

.form-section-divider p {
  font-size: 7pt;
}

.form-section-title h5 {
  font-size: 11pt;
}

.form-section-title h5 {
  font-size: 11pt;
}

.form-section-title h6 {
  font-size: 0.9rem;
}

.form-table-header-page {
  position: absolute;
  top: 0;
  width: 100%;
}

.form-table-header {
  // border-bottom: 1px solid #d3d3d3;
  /* border-top:1px solid #d3d3d3; */
  margin: 0 !important;
  padding: 0 !important;
}

.form-table-header.invoice-line-items {
  border-top: 1px solid #d3d3d3;
}

.invoice-line-items {
  line-height: 6pt;
}

.invoice-line-items label {
  color: #d3d3d3;
}

.trade-line-item {
  p {
    font-size: 6pt;
  }
  span {
    font-size: 6;
  }
}

.invoice-line-item {
  line-height: 6pt;
  span {
    font-size: 6pt;
  }
  p {
    font-size: 6pt;
    font-weight: 700;
  }
}

.pending-invoice {
  font-style: italic !important;
}

.form-border-divider {
  border-bottom: 1px solid #d3d3d3;
  margin: 0 !important;
}

.form-table-line-item {
  border-top: 1px solid #d3d3d3;
  margin: 0 !important;
  padding: 0;
}

.manage-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.form-table-line-item-content {
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.form-table-line-item-content-lg {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.form-table-line-item-id {
  max-width: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.form-table-line-item p {
  font-size: 6pt !important;
}

.form-table-line-item h6 {
  font-size: 7pt !important;
}

.form-inside-section {
  border-bottom: 1px solid #d3d3d3;
  margin: 0 !important;
  padding-bottom: 20px;
}

.form-pdf-content p {
  font-size: 7pt;
}

.form-section-footer h6 {
  font-size: 8pt;
}

.form-section-footer h4 {
  font-size: 11pt;
}

.form-totals h6 {
  font-size: 0.9rem;
}

.form-footer {
  padding: 20px;
}

#form-footer-logo {
  width: 24px;
}

.form-footer p {
  font-size: 5pt;
}

.form-approval p {
  font-size: 0.8rem;
}

.form-checkbox {
  border: 1px solid #586275;
  height: 12px;
  width: 12px;
}

.form-checked {
  background-color: #000;
}

.selectedInvoice {
  border: 3px solid #000;
  margin: 0 -6px 0 -6px !important;
  padding-left: 4px;
  padding-right: 4px;
}

/* .addUser-menu-open{
      box-shadow: 0px 0px 15px 1px rgba(69,65,78,0.2);
  } */

.bottom-border {
  border-bottom: 1px solid;
  padding: 0 0 5px 0;
}

.bottom-border-black {
  border-bottom: 1px solid #000;
  padding: 5px 0;
}
