$colors: (
  body: #f8fbff,
  primary: #121b33,
  accent: #4b7cdd,
  success: #24d19e,
  secondary: #9ca6bb,
  warn: #fa824d,
  danger: #f44336,
  purple: #9e3e73,
  pink: #d6246e,
  btblue: #52abe1,
  olive: #648c64,
  gold: #988053,
);

@function color($color-name) {
  @return map-get($colors, $color-name);
}

.gold {
  color: #9b804d;
}

.bg-gold {
  background-color: #9b804d;
}

.bg-shade-gold {
  background-color: rgba(#9b804d, 0.1);
}

// PRIMARY
.dkblue,
.primary {
  color: color(primary) !important;
}
.bg-dkblue,
.bg-primary {
  background-color: color(primary) !important;
}
.border-dkblue,
.border-primary {
  border-color: color(primary) !important;
}
.bg-shade-dkblue,
.bg-shade-primary {
  background-color: rgba(color(primary), 0.2);
}
.ltdkblue {
  color: lighten(color(primary), 20%);
}
.bg-ltdkblue {
  background-color: lighten(color(primary), 20%);
}
.border-ltdkblue {
  border-color: lighten(color(primary), 20%) !important;
}
.ltltdkblue {
  color: lighten(color(primary), 50%);
}
.bg-ltltdkblue {
  background-color: lighten(color(primary), 50%);
}
.border-ltltdkblue {
  border-color: lighten(color(primary), 50%);
}
.dkdkblue {
  color: darken(color(primary), 20%);
}
.bg-dkdkblue {
  background-color: darken(color(primary), 20%);
}
.border-dkdkblue {
  border-color: darken(color(primary), 20%);
}

// ACCENT
.ltblue,
.accent {
  color: color(accent) !important;
}
.bg-ltblue,
.bg-accent {
  background-color: color(accent) !important;
}
.border-ltblue,
.border-accent {
  border-color: color(accent) !important;
}
.bg-shade-ltblue,
.bg-shade-accent {
  background-color: rgba(color(accent), 0.1);
}
.border-shade-ltblue,
.border-shade-accent {
  border-color: rgba(color(accent), 0.1) !important;
}
.ltltblue {
  color: lighten(color(accent), 20%);
}
.bg-ltltblue {
  background-color: lighten(color(accent), 20%);
}
.border-ltltblue {
  border-color: lighten(color(accent), 20%);
}
.ltltltblue {
  color: lighten(color(accent), 35%);
}
.bg-ltltltblue {
  background-color: lighten(color(accent), 35%);
}
.border-ltltltblue {
  border-color: lighten(color(accent), 35%);
}
.dkltblue {
  color: darken(color(accent), 20%);
}
.bg-dkltblue {
  background-color: darken(color(accent), 20%);
}
.border-dkltblue {
  border-color: darken(color(accent), 20%);
}
.bg-ltblue:hover {
  background-color: darken(color(accent), 10%);
}

// SUCCESS
.green,
.success {
  color: color(success) !important;
}
.bg-green,
.bg-success {
  background-color: color(success) !important;
}
.border-green,
.border-success {
  border-color: color(success);
}
.bg-shade-green,
.bg-shade-success {
  background-color: rgba(color(success), 0.2);
}
.ltgreen {
  color: lighten(color(success), 20%);
}
.bg-ltgreen {
  background-color: lighten(color(success), 20%);
}
.border-ltgreen {
  border-color: lighten(color(success), 20%);
}
.ltltgreen {
  color: lighten(color(success), 50%);
}
.bg-ltltgreen {
  background-color: lighten(color(success), 50%);
}
.border-ltltgreen {
  border-color: lighten(color(success), 50%);
}
.dkgreen {
  color: darken(color(success), 20%) !important;
}
.bg-dkgreen {
  background-color: darken(color(success), 20%) !important;
}
.border-dkgreen {
  border-color: darken(color(success), 20%);
}
.bg-green:hover {
  background-color: darken(color(success), 10%);
}

// WARN
.orange,
.warn {
  color: color(warn) !important;
}
.bg-orange,
.bg-warn {
  background-color: color(warn) !important;
}
.border-orange,
.border-warn {
  border-color: color(warn);
}
.bg-shade-orange,
.bg-shade-warn {
  background-color: rgba(color(warn), 0.1);
}
.ltorange {
  color: lighten(color(warn), 20%);
}
.bg-ltorange {
  background-color: lighten(color(warn), 20%);
}
.border-ltorange {
  border-color: lighten(color(warn), 20%);
}
.ltltorange {
  color: lighten(color(warn), 50%);
}
.bg-ltltorange {
  background-color: lighten(color(warn), 50%);
}
.border-ltltorange {
  border-color: lighten(color(warn), 50%);
}
.dkorange {
  color: darken(color(warn), 20%);
}
.bg-dkorange {
  background-color: darken(color(warn), 20%);
}
.border-dkorange {
  border-color: darken(color(warn), 20%);
}
.bg-orange:hover {
  background-color: darken(color(warn), 10%);
}

// DANGER
.red,
.danger {
  color: color(danger) !important;
}
.bg-red,
.bg-danger {
  background-color: color(danger) !important;
}
.border-red,
.border-danger {
  border-color: color(danger) !important;
}
.bg-shade-red,
.bg-shade-danger {
  background-color: rgba(color(danger), 0.1);
}
.ltred {
  color: lighten(color(danger), 20%);
}
.bg-ltred {
  background-color: lighten(color(danger), 20%);
}
.border-ltred {
  border-color: lighten(color(danger), 20%);
}
.ltltred {
  color: lighten(color(danger), 50%);
}
.bg-ltltred {
  background-color: lighten(color(danger), 50%);
}
.border-ltltred {
  border-color: lighten(color(danger), 50%);
}
.dkred {
  color: darken(color(danger), 20%);
}
.bg-dkred {
  background-color: darken(color(danger), 20%);
}
.border-dkred {
  border-color: darken(color(danger), 20%);
}
.bg-red:hover {
  background-color: darken(color(danger), 10%);
}

// GRAY
.gray,
.secondary {
  color: color(secondary) !important;
}
.bg-gray,
.bg-secondary {
  background-color: color(secondary) !important;
}
.border-gray,
.border-secondary {
  border-color: color(secondary);
}
.bg-shade-gray,
.bg-shade-secondary {
  background-color: rgba(color(secondary), 0.2);
}
.ltgray {
  color: lighten(color(secondary), 20%) !important;
}
.bg-ltgray {
  background-color: lighten(color(secondary), 20%);
}
.border-ltgray {
  border-color: lighten(color(secondary), 20%);
}
.ltltgray {
  color: lighten(color(secondary), 50%);
}
.bg-ltltgray {
  background-color: lighten(color(secondary), 50%) !important;
}
.border-ltltgray {
  border-color: lighten(color(secondary), 50%);
}
.dkgray {
  color: darken(color(secondary), 30%) !important;
}
.bg-dkgray {
  background-color: darken(color(secondary), 20%);
}
.border-dkgray {
  border-color: darken(color(secondary), 20%) !important;
}
.bg-gray:hover {
  background-color: darken(color(secondary), 10%);
}

.white {
  color: #fff !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.bg-body {
  background-color: color(body);
}
.black {
  color: #000;
}

// PURPLE
.purple {
  color: color(purple) !important;
}
.bg-purple {
  background-color: color(purple) !important;
}
.border-purple {
  border-color: color(purple) !important;
}
.bg-shade-purple {
  background-color: rgba(color(purple), 0.2);
}
.ltpurple {
  color: lighten(color(purple), 20%);
}
.bg-ltpurple {
  background-color: lighten(color(purple), 20%);
}
.bg-shade-ltpurple {
  background-color: rgba(color(purple), 0.13);
}
.border-ltpurple {
  border-color: lighten(color(purple), 20%);
}
.ltltpurple {
  color: lighten(color(purple), 50%);
}
.bg-ltltpurple {
  background-color: lighten(color(purple), 50%);
}
.border-ltltpurple {
  border-color: lighten(color(purple), 50%);
}
.dkdkpurple {
  color: darken(color(purple), 20%);
}
.bg-dkdkpurple {
  background-color: darken(color(purple), 20%);
}
.border-dkdkpurple {
  border-color: darken(color(purple), 20%);
}

.gold {
  color: color(gold) !important;
}
.bg-gold {
  background-color: color(gold) !important;
}
.border-gold {
  border-color: color(gold);
}
.bg-shade-gold {
  background-color: rgba(color(gold), 0.1);
}
.ltgold {
  color: lighten(color(gold), 20%);
}
.bg-ltgold {
  background-color: lighten(color(gold), 20%);
}
.border-ltgold {
  border-color: lighten(color(gold), 20%);
}
.ltltgold {
  color: lighten(color(gold), 50%);
}
.bg-ltltgold {
  background-color: lighten(color(gold), 50%);
}
.border-ltltgold {
  border-color: lighten(color(gold), 50%);
}
.dkgold {
  color: darken(color(gold), 20%);
}
.bg-dkgold {
  background-color: darken(color(gold), 20%);
}
.border-dkgold {
  border-color: darken(color(gold), 20%);
}
.bg-gold:hover {
  background-color: darken(color(gold), 10%);
}

// pink
.pink {
  color: color(pink) !important;
}
.bg-pink {
  background-color: color(pink) !important;
}
.border-pink {
  border-color: color(pink) !important;
}
.bg-shade-pink {
  background-color: rgba(color(pink), 0.2);
}
.ltpink {
  color: lighten(color(pink), 20%);
}
.bg-ltpink {
  background-color: lighten(color(pink), 20%);
}
.bg-shade-ltpink {
  background-color: rgba(color(pink), 0.13);
}
.border-ltpink {
  border-color: lighten(color(pink), 20%);
}
.ltltpink {
  color: lighten(color(pink), 50%);
}
.bg-ltltpink {
  background-color: lighten(color(pink), 50%);
}
.border-ltltpink {
  border-color: lighten(color(pink), 50%);
}
.dkdkpink {
  color: darken(color(pink), 20%);
}
.bg-dkdkpink {
  background-color: darken(color(pink), 20%);
}
.border-dkdkpink {
  border-color: darken(color(pink), 20%);
}

// btblue
.btblue {
  color: color(btblue) !important;
}
.bg-btblue {
  background-color: color(btblue) !important;
}
.border-btblue {
  border-color: color(btblue) !important;
}
.bg-shade-btblue {
  background-color: rgba(color(btblue), 0.2);
}
.ltbtblue {
  color: lighten(color(btblue), 20%);
}
.bg-ltbtblue {
  background-color: lighten(color(btblue), 20%);
}
.bg-shade-ltbtblue {
  background-color: rgba(color(btblue), 0.13);
}
.border-ltbtblue {
  border-color: lighten(color(btblue), 20%);
}
.ltltbtblue {
  color: lighten(color(btblue), 50%);
}
.bg-ltltbtblue {
  background-color: lighten(color(btblue), 50%);
}
.border-ltltbtblue {
  border-color: lighten(color(btblue), 50%);
}
.dkdkbtblue {
  color: darken(color(btblue), 20%);
}
.bg-dkdkbtblue {
  background-color: darken(color(btblue), 20%);
}
.border-dkdkbtblue {
  border-color: darken(color(btblue), 20%);
}

// pink
.pink {
  color: color(pink) !important;
}
.bg-pink {
  background-color: color(pink) !important;
}
.border-pink {
  border-color: color(pink) !important;
}
.bg-shade-pink {
  background-color: rgba(color(pink), 0.2);
}
.ltpink {
  color: lighten(color(pink), 20%);
}
.bg-ltpink {
  background-color: lighten(color(pink), 20%);
}
.bg-shade-ltpink {
  background-color: rgba(color(pink), 0.13);
}
.border-ltpink {
  border-color: lighten(color(pink), 20%);
}
.ltltpink {
  color: lighten(color(pink), 50%);
}
.bg-ltltpink {
  background-color: lighten(color(pink), 50%);
}
.border-ltltpink {
  border-color: lighten(color(pink), 50%);
}
.dkdkpink {
  color: darken(color(pink), 20%);
}
.bg-dkdkpink {
  background-color: darken(color(pink), 20%);
}
.border-dkdkpink {
  border-color: darken(color(pink), 20%);
}

// olive
.olive {
  color: color(olive) !important;
}
.bg-olive {
  background-color: color(olive) !important;
}
.border-olive {
  border-color: color(olive) !important;
}
.bg-shade-olive {
  background-color: rgba(color(olive), 0.2);
}
.ltolive {
  color: lighten(color(olive), 20%);
}
.bg-ltolive {
  background-color: lighten(color(olive), 20%);
}
.bg-shade-ltolive {
  background-color: rgba(color(olive), 0.13);
}
.border-ltolive {
  border-color: lighten(color(olive), 20%);
}
.ltltolive {
  color: lighten(color(olive), 50%);
}
.bg-ltltolive {
  background-color: lighten(color(olive), 50%);
}
.border-ltltolive {
  border-color: lighten(color(olive), 50%);
}
.dkdkolive {
  color: darken(color(olive), 20%);
}
.bg-dkdkolive {
  background-color: darken(color(olive), 20%);
}
.border-dkdkolive {
  border-color: darken(color(olive), 20%);
}
