@import url('../node_modules/aos/dist/aos');
@import url('./assets/demo/default/base/style.bundle');
@import url('./assets/vendors/base/vendors.bundle');
@import '~bootstrap/scss/bootstrap';
@import url('./assets/css/default');
@import './assets/css/colors';
@import './assets/css/main';
@import './assets/css/forms';
@import './assets/css/themes';
@import './assets/scss/blinker';
@import 'material-icons/iconfont/material-icons.css';
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// @import '~@angular/material/theming';
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$oneCCApp-primary: mat-palette($mat-indigo);
$oneCCApp-accent: mat-palette($mat-blue);
// The warn palette is optional (defaults to red).
$oneCCApp-warn: mat-palette($mat-red);
$oneCCApp-success: mat-palette($mat-green);
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$oneCCApp-theme: mat-light-theme(
  (
    color: (
      primary: $oneCCApp-primary,
      accent: $oneCCApp-accent,
      warn: $oneCCApp-warn,
      success: $oneCCApp-success,
    ),
  )
);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($oneCCApp-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  background-color: lighten(color(accent), 39%) !important;
  height: 100%;
  min-height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

*,
::after,
::before {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  display: none;
}

.nav-closed {
  width: calc(100% - 64px) !important;
}

.nav-open {
  width: calc(100% - 169px) !important;
}

.disabled {
  pointer-events: none;
}

.col {
  min-width: 0;
}

.fs-18 {
  font-size: 18px;
}

mark {
  background-color: lighten(color(success), 20%);
  padding-right: 0px;
  padding-left: 0px;
}

.not-allowed {
  cursor: not-allowed !important;
}
