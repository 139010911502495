/* You can add global styles to this file, and also import other style files */

@import 'src/assets/css/colors';
html,
body {
  min-height: 100%;
  height: 100%;
}

body {
  background-color: #f8fbff !important;
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif !important;
}

*,
::after,
::before {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  display: none;
}

.blue-spinner circle {
  stroke: #6b8bc9 !important;
}

/* Snackbar style for snackbars without action. Centered */

.mat-simple-snackbar span {
  margin: auto;
  text-align: center;
}

/* Fix for the entire side nav view moving up when a confirmation dialog was opened. */

.main-nav {
  top: 0px !important;
}

/* Snackbar style for snackbars with an action */

.action .mat-simple-snackbar span {
  margin: 0 !important;
  text-align: left !important;
}

/* Z-INDEX */

// .app-container {
//   height: 100%;
//   overflow-y: scroll;
//   position: relative;
//   width: 100%;
// }

app-content-no-nav {
  margin-left: 0 !important;
}

.app-content-nav-open {
  height: 100%;
  padding-left: 169px;
  position: absolute;
  width: 100%;
}

.app-content-nav-closed {
  height: 100%;
  padding-left: 64px;
  position: absolute;
  width: 100%;
}

.app-content-notifications-open {
  height: 100%;
  padding-left: 609px !important;
  position: absolute;
  width: 100%;
}

.app-content-notifications-sidenav-closed {
  height: 100%;
  padding-left: 499px !important;
  position: absolute;
  width: 100%;
}

.app-content-search-open {
  height: 100%;
  padding-left: 609px !important;
  position: absolute;
  width: 100%;
}

.app-content-search-sidenav-closed {
  height: 100%;
  padding-left: 499px !important;
  position: absolute;
  width: 100%;
}

.nav-closed {
  width: calc(100% - 64px);
}

.nav-open {
  width: calc(100% - 169px);
}

.notifications-sidenav-open {
  width: calc(100% - 609px);
}

.notifications-sidenav-closed {
  width: calc(100% - 499px);
}

#app-sidenav {
  position: fixed;
  z-index: 1000 !important;
}

#app-search-overlay-panel {
  position: fixed;
  top: 0;
  z-index: 999 !important;
}

#app-messaging-overlay-panel {
  position: fixed;
  top: 0;
  z-index: 999 !important;
}

#app-notification-overlay-panel {
  position: fixed;
  top: 0;
  z-index: 999 !important;
}

#app-time-log-panel {
  position: fixed;
  top: 0;
  z-index: 999 !important;
}

router-outlet {
  z-index: 998;
}

/* PORTLETS */

.m-portlet {
  border-color: #d3d7e9;
  border-radius: 10px;
  overflow: hidden !important;
}

input {
  color: color(primary);
}

textarea {
  color: color(primary);
}

select {
  color: color(primary);
}

.task {
  padding: 0.15rem;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-semibold {
  font-weight: 500 !important;
}

.font-weight-bold {
  font-weight: 600 !important;
}

.font-weight-black {
  font-weight: 700 !important;
}

.number-badge {
  border-radius: 10px;
  display: inline-block;
  line-height: 20px;
  font-size: 11px;
  font-weight: 600;
  min-width: 20px;
  padding: 0px 2px;
  text-align: center;
}

.button-badge {
  background-color: #dae2fc;
  border-radius: 5px;
  display: inline-block;
  line-height: 18px;
  font-size: 11px;
  font-weight: 500;
  min-width: 20px;
  padding: 0px 3px;
  text-align: center;
}

.small-badge {
  border-radius: 10px;
  line-height: 18px !important;
  height: 18px !important;
  font-size: 11px !important;
  font-weight: 600;
  min-width: 18px;
  padding: 0px 5px;
  text-align: center;
  transition: all 0.3s ease !important;
}

.sidenav-badge {
  border-radius: 10px;
  line-height: 16px;
  font-size: 11px !important;
  font-weight: 600;
  min-width: 18px;
  padding: 0px 5px;
  position: absolute;
  right: 6px;
  text-align: center;
  top: 10px;
  transition: all 0.3s ease !important;
}

.sidenav-badge-hidden {
  opacity: 0;
}

#timeline {
  display: inline-block;
  vertical-align: middle;
  margin: 10px 14px 10px 30px;
}

#timeline .step {
  border: 1px solid #f0f4f8;
  display: inline-block;
  height: 10px;
  width: 18px !important;
  vertical-align: middle;
}

#timeline .step-first {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

#timeline .step-last {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.lh-8 {
  line-height: 8px !important;
}

.lh-14 {
  line-height: 14px !important;
}

.lh-16 {
  line-height: 16px !important;
}

.lh-18 {
  line-height: 18px !important;
}

.lh-20 {
  line-height: 20px !important;
}

.lh-22 {
  line-height: 22px !important;
}

.lh-24 {
  line-height: 24px !important;
}

.lh-26 {
  line-height: 26px !important;
}

.lh-28 {
  line-height: 28px !important;
}

.lh-30 {
  line-height: 30px !important;
}

.lh-32 {
  line-height: 32px !important;
}

.lh-34 {
  line-height: 34px !important;
}

.lh-36 {
  line-height: 36px !important;
}

.lh-38 {
  line-height: 38px !important;
}

.lh-40 {
  line-height: 40px !important;
}

.lh-42 {
  line-height: 42px !important;
}

.lh-44 {
  line-height: 44px !important;
}

.lh-46 {
  line-height: 46px !important;
}

.br-0 {
  border-radius: 0px !important;
}

.br-4 {
  border-radius: 4px !important;
}

.br-5 {
  border-radius: 5px !important;
}

.br-6 {
  border-radius: 6px !important;
}

.br-10 {
  border-radius: 10px !important;
}

.br-15 {
  border-radius: 15px !important;
}

.br-50 {
  border-radius: 50% !important;
}

.no-scroll {
  height: 100%;
  overflow: hidden;
}

.col-0 {
  width: 0px !important;
}

.pulse-dot-success {
  background: color(success);
  border-radius: 50%;
  height: 15px;
  width: 15px;
  position: absolute;
  top: -5px;
  left: -5px;
  box-shadow: 0 0 0 0 color(success);
  transform: scale(1);
  animation: pulse-success 1s infinite;
  z-index: 1001;
}
@keyframes pulse-success {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(36, 209, 158, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 15px rgba(36, 209, 158, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(36, 209, 158, 0);
  }
}

.pulse-after-dot-success {
  background: color(success);
  border-radius: 50%;
  height: 15px;
  width: 15px;
  position: absolute;
  top: -5px;
  right: -5px;
  box-shadow: 0 0 0 0 color(success);
  transform: scale(1);
  animation: pulse-after-success 1s infinite;
  z-index: 1001;
}
@keyframes pulse-after-success {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(36, 209, 158, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 15px rgba(36, 209, 158, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(36, 209, 158, 0);
  }
}

.pulse-dot-accent {
  background: color(accent);
  border-radius: 50%;
  height: 15px;
  width: 15px;
  position: absolute;
  top: -5px;
  left: -5px;
  box-shadow: 0 0 0 0 color(accent);
  transform: scale(1);
  animation: pulse-accent 1s infinite;
  z-index: 1001;
}
@keyframes pulse-accent {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(75, 124, 221, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 15px rgba(75, 124, 221, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(75, 124, 221, 0);
  }
}
.pulse-dot-danger {
  background: color(danger);
  border-radius: 50%;
  height: 15px;
  width: 15px;
  position: absolute;
  top: -5px;
  left: -5px;
  box-shadow: 0 0 0 0 color(danger);
  transform: scale(1);
  animation: pulse-danger 1s infinite;
  z-index: 1001;
}

.pulse-dot-danger-right {
  background: color(danger);
  border-radius: 50%;
  height: 15px;
  width: 15px;
  position: absolute;
  top: -5px;
  right: -5px;
  box-shadow: 0 0 0 0 color(danger);
  transform: scale(1);
  animation: pulse-danger 1s infinite;
  z-index: 1001;
}
@keyframes pulse-danger {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(244, 67, 54, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 15px rgba(244, 67, 54, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(244, 67, 54, 0);
  }
}

.overlay-icon-danger {
  background-color: color(danger);
  border: 2px solid #fff;
  border-radius: 10px;
  line-height: 12px;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 20px;

  mat-icon {
    font-size: 14px;
    height: 14px;
    line-height: 14px !important;
    margin-top: 1px;
    width: 14px;
  }
}

.strike {
  text-decoration: line-through;
}

.no-strike {
  text-decoration: none !important;
}

.user-not-found {
  border: 2px solid #fff;
  border-radius: 50%;
  height: 22px;
  position: absolute;
  right: 4px;
  text-align: center;
  top: 12px;
  width: 22px;
}

.user-not-found i {
  font-size: 14px;
  line-height: 18px;
  padding-left: 0.02em;
}

.icon-click {
  cursor: pointer;
  opacity: 0.6;
}

.icon-click:hover {
  opacity: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.full-width {
  width: 100% !important;
}

.full-height {
  width: 100% !important;
}

.min-width-200 {
  min-width: 400px !important;
}

.form-section-heading {
  /* margin:30px 0; */
  margin-bottom: 30px;
}

.margin-top-2 {
  margin-top: 3rem;
}

.margin-0 {
  margin: 0 !important;
}

.padding-0 {
  padding: 0px !important;
}

.opacity0 {
  opacity: 0;
}

.opacity1 {
  opacity: 0.1;
}

.opacity2 {
  opacity: 0.2;
}

.opacity3 {
  opacity: 0.3;
}

.opacity4 {
  opacity: 0.4;
}

.opacity5 {
  opacity: 0.5;
}

.opacity6 {
  opacity: 0.6;
}

.opacity7 {
  opacity: 0.7;
}

.opacity8 {
  opacity: 0.7;
}

.opacity9 {
  opacity: 0.7;
}

.opacity10 {
  opacity: 1 !important;
}

.mat-option {
  height: auto !important;
  min-height: 48px !important;
  padding:16px !important;
}

.mat-select-panel::-webkit-scrollbar,
.mat-autocomplete-panel::-webkit-scrollbar,
.mat-dialog-content::-webkit-scrollbar {
  display: inline-block;
}

.mat-select-panel::-webkit-scrollbar:vertical,
.mat-autocomplete-panel::-webkit-scrollbar:vertical,
.mat-dialog-content::-webkit-scrollbar:vertical {
  width: 10px;
}

.mat-select-panel::-webkit-scrollbar:horizontal,
.mat-autocomplete-panel::-webkit-scrollbar:horizontal,
.mat-dialog-content::-webkit-scrollbar:horizontal {
  height: 10px;
}

.mat-select-panel::-webkit-scrollbar-thumb,
.mat-autocomplete-panel::-webkit-scrollbar-thumb,
.mat-dialog-content::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.2);
}

.ps > .ps__rail-y > .ps__thumb-y {
  background-color: rgba(0, 0, 0, 0.2);
}

.ps > .ps__rail-y > .ps__thumb-y:hover,
.ps > .ps__rail-y > .ps__thumb-y:focus {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.ease {
  transition: all 0.3s linear;
}

.easeTask {
  transition: all 0.2s linear;
}

.ease-fast {
  transition: all 0.2s linear;
}

.ease-turbo {
  transition: all 0.1s linear;
}

.lh2 {
  line-height: 1.8rem;
}

.lh3 {
  line-height: 4rem;
}

.pt12 {
  padding-top: 12px;
}

.hover-u {
  cursor: pointer;
}

.hover-u:hover {
  text-decoration: underline !important;
}

.text-underline {
  text-decoration: underline;
}

.card-avatar {
  /* background-color:#fafafa;
	border:1px solid #d3d3d3; */
  background-color: #fafafa;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  margin: 0px 15px 15px 0;
  padding: 10px 20px 10px 10px;
  position: relative;
  width: auto;
  min-width: 220px;
}

/* .card-avatar:hover{
    background-color:#f9f9f9;
    cursor:pointer;
} */

.card-avatar h5 {
  margin-bottom: 1px;
  margin-top: 6px;
}

.card-avatar img {
  border: 3px solid #fff;
  border-radius: 25px;
  height: 50px;
  width: 50px;
}

.card-avatar-left {
  display: inline-block;
  padding: 0 15px 0 20px;
  position: relative;
  vertical-align: middle;
}

.card-avatar-right {
  display: inline-block;
  padding: 0 20px 0 0px;
  vertical-align: middle;
}

.card-avatar .icon-click {
  position: absolute;
  right: 20px;
  top: 20px;
}

.card-label-top-right {
  background-color: #6b8bc9;
  border-radius: 5px;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.07);
  color: #fff;
  font-size: 0.8rem;
  font-weight: 500;
  padding: 3px 7px;
  position: absolute;
  right: -7px;
  top: -7px;
}

.table-avatar img {
  border-radius: 50%;
  height: 30px;
  width: 30px;
}

.table-avatar-lg img {
  border-radius: 50%;
  height: 37px;
  width: 37px;
}

.small-avatar img {
  border-radius: 50%;
  height: 22px;
  width: 22px;
}

.user-avatar {
  border-radius: 50%;
  /* box-shadow:0 3px 3px -2px rgba(0,0,0,.04),0 3px 4px 0 rgba(0,0,0,.04),0 1px 8px 0 rgba(0,0,0,.01);	 */
  height: 34px;
  width: 34px;
  overflow: visible;
  position: relative;
}

.user-avatar img {
  border: 2px solid #fff;
  border-radius: 50%;
  height: 34px;
  width: 34px;
}

.user-avatar-circle {
  border: 2px solid #fff;
  border-radius: 50%;
  height: 34px;
  width: 34px;
}

.menu-user-avatar {
  border-radius: 50%;
  /* box-shadow:0 3px 3px -2px rgba(0,0,0,.04),0 3px 4px 0 rgba(0,0,0,.04),0 1px 8px 0 rgba(0,0,0,.01);	 */
  height: 30px;
  width: 30px;
  overflow: visible;
  position: relative;
}

.menu-user-avatar img {
  border: 2px solid #fff;
  border-radius: 50%;
  height: 30px;
  width: 30px;
}

.small-avatar {
  border: 2px solid #fff;
  border-radius: 50%;
  /* box-shadow:0 3px 3px -2px rgba(0,0,0,.04),0 3px 4px 0 rgba(0,0,0,.04),0 1px 8px 0 rgba(0,0,0,.01);	 */
  height: 24px;
  width: 24px;
  overflow: visible;
  position: relative;
}

.project-modal {
  padding: 10px 15px;
  position: relative;
  max-height: 80vh;
  min-width: auto;
  width: auto;
}

.close-modal {
  cursor: pointer;
  font-size: 1.5rem;
  float: right;
}

.pointer {
  cursor: pointer;
}

.draggable {
  cursor: grab !important;
  transition: all 0.3s linear;
}

.draggable:hover {
  background-color: rgba(107, 138, 201, 0.06) !important;
}

.main-label {
  color: #9ca6bb;
  font-size: 0.9rem;
  font-weight: 500;
  /* letter-spacing: .5px; */
  /* text-transform: uppercase; */
  display: inline-block;
  margin-bottom: 0px;
  .material-icons {
    font-size: 1.2rem;
    height: auto;
    vertical-align: middle;
  }
}

.mat-menu-content .main-label {
  margin-left: 10px;
}

.mat-expansion-panel {
  border-radius: 0px !important;
}

.mat-accordion .mat-expansion-panel:first-of-type {
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.mat-accordion .mat-expansion-panel:last-of-type {
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

.avatar-badge {
  /* background-color:rgb(252, 132, 33); */
  border-radius: 50%;
  font-size: 10px;
  height: 14px;
  padding-left: 2.5px;
  padding-top: 0px;
  position: absolute;
  left: 8px;
  bottom: -11px;
  width: 14px;
  /* box-shadow: 0 3px 3px -2px rgba(0,0,0,.5), 0 3px 4px 0 rgba(0,0,0,.14), 0 1px 8px 0 rgba(0,0,0,.1); */
}

.avatar-badge-small {
  /* background-color:rgb(252, 132, 33); */
  border-radius: 50%;
  font-size: 8px;
  height: 12px;
  position: absolute;
  left: 9px;
  bottom: -2px;
  width: 12px;
  /* box-shadow: 0 3px 3px -2px rgba(0,0,0,.5), 0 3px 4px 0 rgba(0,0,0,.14), 0 1px 8px 0 rgba(0,0,0,.1); */
  text-align: center;
}

/* .avatar-badge-small .la{
    margin-top:-13px;
} */

.chip-custom {
  background-color: #fafafa;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  color: #121b33;
  display: inline-block;
  font-weight: 500;
  margin-right: 12px;
  margin-bottom: 12px;
  padding: 4px 6px;
}

.chip-custom span {
  padding: 5px;
}

.chip-custom i {
  /* margin-left:10px; */
  padding: 3px 3px;
}

.chip-custom-md {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  color: #121b33;
  cursor: pointer;
  display: inline-block;
  font-weight: 500;
  margin-right: 15px;
  margin-bottom: 3px;
  padding: 2px 20px;
  position: relative;
  vertical-align: middle;
}

.chip-custom-lg {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  color: #121b33;
  cursor: pointer;
  display: inline-block;
  font-weight: 500;
  margin-right: 15px;
  margin-bottom: 10px;
  padding: 12px 24px;
  position: relative;
}

.chip-custom-lg span {
  padding: 5px;
}

.chip-custom-lg i {
  background-color: #fff;
  font-size: 1.3rem;
  position: absolute;
  right: -5px;
  top: -5px;
}

/* .project-search{
	display:inline-block;
	margin:-3px 0;
	vertical-align:middle;
	width:220px;
}

.project-search-icon{
	padding:6px 7px;
	position:absolute;
}

.project-search input{
	display: inline-block;
    background-color:#fff !important;
    padding:0px 5px 0px 34px;
    font-size: 1.2rem;
    font-weight: 400;
    color: #121B33;
    background: #fff;
    border: 1px solid #e3e6f0;
	border-radius: .25rem;
	line-height:34px;
	vertical-align: middle;
}

.project-search input:focus{
	outline:none;
} */

.click_overlay {
  background-color: transparent;
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 1010;
}

.portlet-table-header {
  /* background-color:#e3e6f0; */
  margin-bottom: 5px;
  padding: 5px 2.2rem;
}

.portlet-table-header .main-label {
  color: #121b33;
  font-weight: 500;
  margin: 0;
}

.item-danger {
  border-left: 4px solid #f44336;
}

.item {
  border-bottom: 1px solid #d3d7e9;
  transition: all 0.3s linear;
}

.item:hover {
  /* box-shadow: 0 2px 2px 0 rgba(0,0,0,.1), 0 1px 1px 0 rgba(0,0,0,.07), 0 1px 8px 0 rgba(0,0,0,.1);*/
  background-color: lighten(color(accent), 38%);
  /* background-color:#f9fafd;	 */
}

.item-no-hover {
  border-bottom: 1px solid #d3d7e9;
  /* border-bottom:1px solid #f4f4f4; */
  /* padding:18px; */
}

.item-last {
  border-bottom: none;
}

.border-bottom-thick {
  border-bottom: 4px solid color(accent) !important;
}

.border-bottom-transparent {
  border-bottom: 4px solid transparent !important;
}

.b-0 {
  border: none !important;
}

.br-0 {
  border-radius: 0px !important;
}

.br-5 {
  border-radius: 5px !important;
}

.br-10 {
  border-radius: 10px !important;
}

.h-0 {
  height: 0;
}

.mat-menu-sm {
  min-width: 180px !important;
}

.mat-menu-lg {
  min-width: 768px !important;
}

.project-timeline {
  padding: 140px 30px 0px 30px !important;
}

.mat-chip-sm .mat-standard-chip {
  min-height: 26px;
  padding: 2px 10px;
}

.mat-chip-sm i {
  font-size: 12px;
}

.subcomponent-main-nav-open {
  height: calc(100% - 115px);
  padding: 20px 20px 20px 190px;
  left: 0;
  position: fixed;
  overflow-y: scroll !important;
  width: calc(100% - 460px);
}

.subcomponent-main-nav-closed {
  height: calc(100% - 115px);
  padding: 20px 20px 20px 90px;
  left: 0;
  position: fixed;
  overflow-y: scroll !important;
  width: calc(100% - 460px);
}

.subcomponent-sub {
  background-color: #fff;
  border-left: 1px solid #a3adce;
  height: calc(100% - 115px);
  padding: 20px 20px 20px 20px;
  /* position:fixed; */
  overflow-y: scroll !important;
  right: 0 !important;
  /* width:500px; */
}

.subcomponent-content {
  overflow-y: hidden;
  padding: 20px 20px 60px 20px;
}

.as-split-gutter {
  flex-grow: 0;
  flex-shrink: 0;
  /* background-color: #eee !important; */
  /* background-color: rgb(233, 239, 252)!important; */
  background-color: #6b8bc9 !important;
  /* border-bottom:2px solid rgb(228, 236, 252); */
  /* border-top:2px solid rgb(228, 236, 252); */
  display: flex;
  align-items: center;
  justify-content: center;
}

.mat-button,
.mat-flat-button,
.mat-icon-button,
.mat-stroked-button {
  min-width: 40px !important;
}

.mat-small-icon {
  font-size: 17px;
  height: 16px !important;
  width: 16px !important;
}

.pulse {
  overflow: visible;
  position: relative;
}

.pulse:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: inherit;
  border-radius: 10px;
  transition: opacity 0.3s, transform 0.3s;
  animation: pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) 1;
  z-index: -1;
}

@keyframes pulse-animation {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}

.border {
  border: 1px solid #dee2e6;
}

.border-none {
  border: none !important;
}

.border-thick {
  border: 3px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.mat-button-toggle-checked {
  background-color: rgba(color(accent), 0.1);
  color: color(primary) !important;
}

:host ::ng-deep .ck-editor__editable_inline {
  min-height: 20px;
  max-height: 300px;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  border-radius: 5px !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  background-color: lighten(color(accent), 40%);
  border-color: lighten(color(primary), 75%);
  color: color(primary);
}

.ck.ck-button,
a.ck.ck-button {
  color: color(primary);
  cursor: pointer !important;
}

.ck.ck-button:hover,
a.ck.ck-button:hover {
  background-color: lighten(color(accent), 35%) !important;
}

.ck.ck-list__item .ck-button.ck-on {
  background-color: lighten(color(accent), 30%) !important;
  color: color(primary) !important;
}

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  border-radius: 5px !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-color: lighten(color(primary), 75%) !important;
}

.ck.ck-editor__main > .ck-editor__editable:focus {
  border: 1px solid color(primary) !important;
  outline: none !important;
  box-shadow: none !important;
  border-collapse: collapse;
}

.ck-content .text-tiny {
  font-size: 1em !important;
  color: color(primary) !important;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-small {
  font-size: 1em !important;
  color: color(primary) !important;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-big {
  font-size: 1.4em !important;
  color: color(primary) !important;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-huge {
  font-size: 1.8em !important;
  color: color(primary) !important;
}

.ck-content p {
  font-size: 1.1em !important;
  color: color(primary) !important;
}

.mat-select-panel mat-option.mat-option {
  height: auto;
}

.mat-option-text.mat-option-text {
  line-height: 20px;
  white-space: normal;
}

.signature {
  font-family: 'Dawning of a New Day', cursive !important;
}
